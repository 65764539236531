import React from 'react'
// import './specials.scss'
import AutoMenu from '../../react/AutoMenu'
import {Link} from 'react-router-dom'
export default function () {
  return(
    <div className="catering-page">
      {/* <div className="top-banner" style={{backgroundImage: "url(https://s3-media2.fl.yelpcdn.com/bphoto/OH4HA20N3PcXyEW4wHeaIA/o.jpg)"}}>
        <h1>Pablito's Catering</h1>
      </div>

      <div className="content">

      </div> */}

      <AutoMenu tagsToShow={["catering"]} />

      <div className="full-menu-link-wrapper">
        <Link to="/order" className="button">Go to Full Menu</Link>
      </div>
    </div>
  )
}
