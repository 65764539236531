import React from 'react'
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';

import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import './Party.scss'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  textField: {
    // marginLeft: theme.spacing.unit,
    // marginRight: theme.spacing.unit,
    // width: 300,
    // background: "white",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

const fields = [
  {id: "name", label: "Name", required: true },
  {id: "phone", label: "Phone", required: true },
  {id: "email", label: "Email", },
  // {id: "message", label: "Message" },


]

function Jobs ({classes}) {
  return(
    <div className="filming-page page-wrapper">
      <header
        className="page-header"
        style={{
          background: `url(https://afag.imgix.net/moores-delicatessen/the-back-room.jpg?w=1200)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "40vh",
        }}

      >

        <h1>Jobs</h1>
      </header>
      <div className="article">
        <h2>Work with Us</h2>
        <p>We'd love to have you join our team!</p>
        <p>Fill out the form below:</p>
      </div>
      <div className="form-wrapper">
        <form name="jobs" method="POST" data-netlify="true" className="filming-form">
          <input type="hidden" name="form-name" value="jobs" />
          {fields.map(({id, label, required}) =>
            <TextField
              name={id}
              id={id}
              label={label}
              variant="filled"
              className={classes.textField}
              required={!!required}
              // fullWidth
            />
          )}
          <TextField
            id="message"
            label="Message"
            multiline
            fullWidth
            variant="filled"
            className={"notes"}
          />

          <div className="file-upload">
            <label htmlFor="file">Resume</label>
            <input
              id="file"
              name="file"
              type="file"/>
          </div>




          <Button type="submit" variant="contained" className={"submit-button"}>
            Submit
            {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
            {/* <Icon className={classes.rightIcon}>send</Icon> */}
          </Button>


        </form>
      </div>
    </div>
  )
}

const photos = [
  "https://res.cloudinary.com/dgps1radv/image/upload/v1548992217/danstuff/a8b61b_a5281f711b72425194cd509568b59320_mv2_d_1536_2048_s_2.jpg",
  "https://res.cloudinary.com/dgps1radv/image/upload/v1548992217/danstuff/a8b61b_3bfc851dd58e4ff096feb5e4df53322f_mv2.jpg",
  "https://res.cloudinary.com/dgps1radv/image/upload/v1548992217/danstuff/a8b61b_703b618a91eb46f3aa2b97905da02b2f_mv2_d_2048_2048_s_2.jpg"
]

export default withStyles(styles)(Jobs)
