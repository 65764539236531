import React, {useEffect, useState} from 'react'

let STRIPE_PUBLIC_KEY
if (process.env.NODE_ENV === 'development') {
  STRIPE_PUBLIC_KEY = 'pk_test_4StUZOAUBEimWV33hJZZn5f5'
} else {
  STRIPE_PUBLIC_KEY = 'pk_live_Sq151n5SUu6qAtZDKtSdw0tv'
}


export default function useStripe () {
  const [stripe, setStripe] = useState()
  useEffect(function () {
    if (window.Stripe) {
      setStripe(window.Stripe(STRIPE_PUBLIC_KEY));
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        setStripe(window.Stripe(STRIPE_PUBLIC_KEY));
      });
    }
  }, [0])
  return stripe
}
