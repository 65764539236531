import React from 'react'
// import './specials.scss'
import AutoMenu from '../../react/AutoMenu'
import {Link} from 'react-router-dom'
export default function () {
  return(
    <div className="ordering-page">
      {/* <div className="top-banner" style={{backgroundImage: "url(https://s3-media2.fl.yelpcdn.com/bphoto/OH4HA20N3PcXyEW4wHeaIA/o.jpg)"}}>
        <h1>Pablito's Catering</h1>
      </div>

      <div className="content">

      </div> */}


      <AutoMenu
        subMenuFilter={subMenuFilter}
        shouldFocus={true} />


    </div>
  )
}


function subMenuFilter (subMenu) {
  const tag = subMenu.tag
  if (!tag) {return true}
  if (tag ===  'catering') {return false}

  const now = new Date()
  const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  const minutes = now.getMinutes() // 0 to 59
  const isWeekday = [1,2,3,4,5].indexOf(day) !== -1
  const isBreakfast = (
    (hours >= 0) && (hours < 12)
  )
  const showBreakfastMenu = isBreakfast || !isWeekday

  const isBreakfastSubmenu = tag.indexOf('breakfast') !== -1
  // const isDinnerSubmenu = tag.indexOf('dinner') !== -1

  if (showBreakfastMenu && showBreakfastMenu) { return true }
  // if (isDinnerSubmenu && !showBreakfastMenu) { return true }

  return false
}
