import React from 'react'
import sauce from './Pages/sauce'
import spice from './Pages/spice'
import aboutus from './Pages/aboutus'


const urlmapping = {

  "/breakfast-catering-package": { redirect: '/catering' },
  "/sandwich-catering-package": { redirect: '/catering' },
  "/items/:key": { redirect: '/menu' },
}
const mappingArray = []
for (let key in urlmapping) {
  urlmapping[key].origin = key
  mappingArray.push(urlmapping[key])
}
export default mappingArray
