import React from 'react'
// import './specials.scss'
import {Link} from 'react-router-dom'
export default function () {
  return(
    <div className="page">
      <h1>About Us</h1>

      <p>Opened in October 2010 with the goal of bringing a fresh, quality take on traditional deli fare, Moore's offers outstanding breakfast, soups, sandwiches, and salads.</p>

      <p>We are proud to serve a handful of select micro-brew craft beers. Plus the best brewed coffee around and your favorite specialty coffee drinks.</p>

      <h2>Email</h2>
      <p><a href="mailto:moores@afoodapart.com">moores@afoodapart.com</a>
      </p>
      <h2>Address</h2>
      <p>271 E Orange Grove Ave<br/>Burbank, CA 91502</p>


      <h2>Phone</h2>
      <p><a href="tel:+18184781251">(818)478-1251</a></p>


    </div>
  )
}
