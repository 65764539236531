import React, { Component, PureComponent } from 'react';
import {NavLink, Link, Redirect} from 'react-router-dom'

import './index.scss'

// import OurStoryPage from './OurStoryPage'
import Alcohol from './Alcohol'
import Order from './Order'
import urls from './urls'
import Contact from './Contact'
import Catering from './Catering'
import GiftCards from './GiftCards'
import Party from './Party'
import Jobs from './Jobs'

const urlMappings = urls.filter(({content, redirect}) => {
  return !!content || !!redirect
}).map(({origin, content, redirect}) => {
  const pageObj = { path: origin, unlisted: true }
  if (content) {
    pageObj.component = content
  }
  if (redirect) {
    console.log(redirect)
    pageObj.component = () => (<Redirect to={redirect}></Redirect>)
  }
  return pageObj
})

export const pages = [
  {path: "/order", label: "Order", component: Order},
  {path: "/menu", label: "Menu", component: Order},
  {path: "/the-backroom", label: "Party", component: Party},
  {path: "/jobs", label: "Jobs", component: Jobs},
  // {path: "/catering", label: "Catering", component: Catering},
  {path: "/catering", label: "Catering", component: Catering},
  {path: "/gift-cards", label: "Gift Card", component: GiftCards},
  {path: "/gift-cards/:status", unlisted: true, component: GiftCards},
  {path: "/about-us", label: "About", component: Contact},
].concat(urlMappings)

export class SiteNav extends PureComponent {
  render() {

      // {path: "/app", label: "App"},


    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, external, label, unlisted}) => {
          if (unlisted) { return null }
          return (<li key={label}>
            {external ?
              <a className="page-link" href={path}>{label}</a> :
              <NavLink className="page-link" exact to={path}>{label}</NavLink>}
          </li>)
        })}
      </ul>
    </nav>
  )}
}

export const customOrderAppPaths = []

const facts = [
  {
    alt: "Cheese Burger",
    text: "Burgers ground fresh in-house daily, including turkey and veggie.",
    img: "https://afag.imgix.net/wild-carvery/santa-fe-turkey-burger.jpg?w=800&auto=format"
  },
  {
    alt: "Kale Salad",
    img: "https://afag.imgix.net/wild-carvery/kale-salad.jpg?w=800&auto=format",
    text: "We use organic or local ingredients wherever we can."
  },
  {
    img: "https://images.unsplash.com/photo-1562349831-826ad5074c1c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80",
    text: "We have all-american grass-fed burgers/"
  },
  {
    alt: "Grilled Chicken Salad",
    img: "https://afag.imgix.net/wild-carvery/chicken-salad-2.jpg?w=800&auto=format",
    text: "We use No-GMO, Zero transfat rice oil for grilling"
  },
  {
    alt: "Chipotle Chicken Sandwich with bacon",
    img : "https://afag.imgix.net/wild-carvery/chipotle-chicken-sandwich-2.jpg?w=800&auto=format",
    text: "No nitrates, preservatives, antibiotics or hormones!"
  },


  {
    alt: "",
    img: "https://afag.imgix.net/wild-carvery/wild-pets.jpg?w=800&auto=format",
    text: "Our Patio is pet friendly."
  }
]

const wildfacts = [
  "Our burgers are ground fresh in-house daily! That includes our Turkey, and Veggie burgers!",
  "All-natural and no rBST on anything!",
  "We support organic, sustainable farm-fresh ingredients",
  "Non-GMO, trans-fat free, rice-oil for grilling",
  "No corn syrup, nitrates, preservatives or antibiotics!"
]

export class Header extends PureComponent {
  render() {
    return (
      <div className="landing-page-wrapper">
        <div className="landing-page">

          <div className="text">
            {/* <div className="hero-logo">
              <img src="https://res.cloudinary.com/dgps1radv/image/upload/v1543299516/danstuff/Artboard_2.png" alt=""/>
            </div> */}
            {/* <p className="large">Organic, Fresh, Natural</p> */}
            {/* <p>9am-3pm daily</p> */}
            {/* <p>come and experience peruvian cuisine</p> */}
            {/* <Link to="/order" className="order-online-link">
            Order Direct</Link>

            <p>
              <a
                className="order-online-chownow-link"
                href="https://ordering.chownow.com/order/6504/locations">
                or order from Chownow
              </a>
            </p> */}
            {/* <p>Pickup & Delivery</p> */}
            {/* <p className="bonus"><span>with 5% rewards</span></p> */}
          </div>


        </div>

        <section className="summary feature-section">
          <div className="content">
            {/* <h2>Moore's Delicatessen</h2> */}



            <p>
              <Link to="/order" className="order-online-link">
              Order Delivery / Pickup</Link>
            </p>

            <p>5% Rewards</p>

            {/* <p>
              <a
                className="order-online-chownow-link"
                href="https://ordering.chownow.com/order/7406/locations">
                or order from Chownow
              </a>
            </p> */}

            <ul>
              <li><div>Mon-Sat:</div> 7:00am - 5:00pm</li>
              <li><div>Sun:</div> 8:00am - 3:00pm</li>
            </ul>


          </div>
        </section>

        <section
          style={{
            backgroundImage: `url(https://afag.imgix.net/moores-delicatessen/mini-picnic-box-sandwiches-serves-5-7.jpg?w=1000)`
          }}
          className="story feature-section">

        </section>

        {/* <section className="reviews-section">
          <h2>Hours</h2>
          <div className="reviews">
            <ul>
              <li><div>Mon-Sat:</div> 7:00am - 5:00pm</li>
              <li><div>Sun:</div> 8:00am - 3:00pm</li>
            </ul>
          </div>
        </section> */}

        {/* <OurStoryPage></OurStoryPage> */}

        {/* <CriticReviews></CriticReviews> */}



      </div>
    );
  }
}




export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div></div>
    );
  }
}
