import React from 'react'
export default function ({data}) {
  const {
    selectedAmount,
    note,
    buyerName,
    recipientName,
    buyerEmail,
    recipientEmail
  } = data
  return(
    <svg height="240px" width="310px" version="1.1" viewBox="0 0 310 240" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <rect height="240" id="path-1" width="310" rx="8" x="0" y="0"/>
      </defs>
      <g id="Page-1" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g id="Rectangle">
          <rect height="240" width="310" fill="#ad0a0c" fillRule="evenodd" rx="8" x="0" y="0"/>
          <rect height="239" width="309" rx="8" stroke="#979797" strokeWidth="1" x="0.5" y="0.5"/>
        </g>
        <text id="$200" fill="#FFFFFF" fontFamily="Helvetica-Bold, Helvetica" fontSize="36">
          <tspan x="115" y="104">${selectedAmount}</tspan>
        </text>
        <image height="53" id="Bitmap" width="287" x="13" y="10" xlinkHref="https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_fill,h_300/bqsg8npyve1pfedlaqyd"/>
        <text id="To:-from@" fill="#FFFFFF" fontFamily="Helvetica-Bold, Helvetica" fontSize="18">
          <tspan x="13" y="136">To: {buyerName} {buyerEmail}</tspan>
        </text>
        <text id="From:-from@-Copy" fill="#FFFFFF" fontFamily="Helvetica-Bold, Helvetica" fontSize="18">
          <tspan x="13" y="157">From: {recipientName} {recipientEmail}</tspan>
        </text>
        <text id="this-is-a-note" fill="#FFFFFF" fontFamily="Helvetica-Oblique, Helvetica" fontSize="18">
          <tspan x="13" y="179">{note}</tspan>
        </text>
      </g>
    </svg>
  )
}
