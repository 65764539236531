import React from 'react'

export default function () {
  return(<div className="page">
    <h1>Our Sauce for BLT Sandwich Will Make You Smile at Wild Carvery Restaurant</h1>
  		<p>You know that just the smell of bacon is enough to get your taste buds salivating. You sniff the air, wondering where the smell is coming from when you recognize it and want to seek it out right away. Okay, so bacon is not always the healthiest choice for you, but there are good options for bacon that are healthier and taste great. When you combine that bacon with fresh lettuce, tomato and have just the right bread, you have a sandwich that is worth writing home about, remembering, and wanting all the time. You can get a BLT anywhere; you want a BLT that stands out from the rest. Here at <a href="https://wildcarvery.com/menu/"><strong>Wild Carvery restaurant</strong></a>, our sauce for our BLT sandwich helps bring the sandwich to the next level and will make you smile with delight.</p>
  <h2>A Better BLT</h2>
  <p>You can go into nearly any restaurant, café or diner and order a BLT. The problem is that no matter how many times you have a BLT and how much you love bacon, too many times there is nothing about the sandwich that stands out. The bacon is overcooked or tastes too fatty or greasy, the lettuce is wilted and has seen better days, and the tomatoes are lifeless, colorless and flavorless. A sandwich like this is certainly not one you will go back for again and again. That is why you need to try us and have a better BLT.</p>
  <h3>What Makes Ours Special</h3>
  <p>Everything about the BLT we offer at Wild Carvery restaurant makes it better than the rest. We take pride in using the best, freshest ingredients for everything we make. We locally source many of our ingredients, so you get fresh each time and use healthier, organic ingredients that taste better and are better for you. Even our sauce for our BLT sandwich is a natural mayo that adds a layer of flavor that you will appreciate time and again.</p>
  <h4>Are You Ready?</h4>
  <p>If you are ready for a sandwich with nitrate free bacon, fresh, local lettuce and tomatoes, served on fresh sourdough with our sauce for our BLT sandwich shining through, then come to us at Wild Carvery restaurant for the BLT you will never forget. You can order yours right now for pick up now or when you need it when you go to our website at <strong><a href="https://wildcarvery.com/">wildcarvery.com</a></strong>. Give our BLT a try and see what a classic sandwich should taste like every time.</p>
  	</div>)

}
